// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-magicrop-crop-video-adobe-premiere-js": () => import("./../../../src/pages/apps/magicrop/crop-video-adobe-premiere.js" /* webpackChunkName: "component---src-pages-apps-magicrop-crop-video-adobe-premiere-js" */),
  "component---src-pages-apps-magicrop-crop-video-apple-final-cut-js": () => import("./../../../src/pages/apps/magicrop/crop-video-apple-final-cut.js" /* webpackChunkName: "component---src-pages-apps-magicrop-crop-video-apple-final-cut-js" */),
  "component---src-pages-apps-magicrop-crop-video-free-instagram-story-js": () => import("./../../../src/pages/apps/magicrop/crop-video-free-instagram-story.js" /* webpackChunkName: "component---src-pages-apps-magicrop-crop-video-free-instagram-story-js" */),
  "component---src-pages-apps-magicrop-crop-video-free-no-watermark-js": () => import("./../../../src/pages/apps/magicrop/crop-video-free-no-watermark.js" /* webpackChunkName: "component---src-pages-apps-magicrop-crop-video-free-no-watermark-js" */),
  "component---src-pages-apps-magicrop-free-facebook-video-cropper-resize-js": () => import("./../../../src/pages/apps/magicrop/free-facebook-video-cropper-resize.js" /* webpackChunkName: "component---src-pages-apps-magicrop-free-facebook-video-cropper-resize-js" */),
  "component---src-pages-apps-magicrop-free-instagram-video-cropper-resize-js": () => import("./../../../src/pages/apps/magicrop/free-instagram-video-cropper-resize.js" /* webpackChunkName: "component---src-pages-apps-magicrop-free-instagram-video-cropper-resize-js" */),
  "component---src-pages-apps-magicrop-free-tiktok-video-cropper-resize-js": () => import("./../../../src/pages/apps/magicrop/free-tiktok-video-cropper-resize.js" /* webpackChunkName: "component---src-pages-apps-magicrop-free-tiktok-video-cropper-resize-js" */),
  "component---src-pages-apps-magicrop-how-to-crop-video-format-keep-subject-center-ipad-app-js": () => import("./../../../src/pages/apps/magicrop/how-to-crop-video-format-keep-subject-center-ipad-app.js" /* webpackChunkName: "component---src-pages-apps-magicrop-how-to-crop-video-format-keep-subject-center-ipad-app-js" */),
  "component---src-pages-apps-magicrop-index-js": () => import("./../../../src/pages/apps/magicrop/index.js" /* webpackChunkName: "component---src-pages-apps-magicrop-index-js" */),
  "component---src-pages-apps-magicrop-magicrop-dynamic-cropping-video-editor-mac-software-js": () => import("./../../../src/pages/apps/magicrop/magicrop-dynamic-cropping-video-editor-mac-software.js" /* webpackChunkName: "component---src-pages-apps-magicrop-magicrop-dynamic-cropping-video-editor-mac-software-js" */),
  "component---src-pages-apps-magicrop-privacy-js": () => import("./../../../src/pages/apps/magicrop/privacy.js" /* webpackChunkName: "component---src-pages-apps-magicrop-privacy-js" */),
  "component---src-pages-apps-magicrop-resize-crop-video-to-the-right-aspect-ratio-js": () => import("./../../../src/pages/apps/magicrop/resize-crop-video-to-the-right-aspect-ratio.js" /* webpackChunkName: "component---src-pages-apps-magicrop-resize-crop-video-to-the-right-aspect-ratio-js" */),
  "component---src-pages-apps-magicrop-terms-js": () => import("./../../../src/pages/apps/magicrop/terms.js" /* webpackChunkName: "component---src-pages-apps-magicrop-terms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

